import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CampaignList from './campaign_list';
import CampaignDetail from './campaign_detail';

class Campaign extends Component {
  componentDidMount(){
  }
  render() {
    //console.log(this.context);
    var params = this.context.router.route.match.params;
    var view;
    if(params.action===undefined){
      view = <CampaignList/>;
    }else{
      if(params.id===undefined){
        view = <CampaignDetail/>;
      }else{
        view = <CampaignDetail action={params.action}/>;
      }
    }
    return (
      <div className="page-wrapper">{view}</div>
    );
  }
}

Campaign.contextTypes = {
  router: PropTypes.object
};

export default Campaign;
