import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
//import Table, {TableBody, TableCell, TableHead, TableRow} from '@material-ui/core/Table';
//import Paper from '@material-ui/core/Paper';
import {TextField, Typography, Button, FormLabel,Toolbar,Chip} from '@material-ui/core';
// import IconButton from '@material-ui/core/IconButton';
// import { Tabs, Tab } from '@material-ui/core';
// import AppBar from '@material-ui/core/AppBar';
import FormGroup from '@material-ui/core/FormGroup';
//import TabContainer from './shares/react_tab_container';
import language from '../layout/language';
//import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
// import AddIcon from '@material-ui/icons/Add';
// import DeleteIcon from '@material-ui/icons/Delete';
// import Media from './media';
// import ImageControl from './shares/react_image_control';

//import classNames from 'classnames';
import Utils from '../api/api';
import theme, {styles} from '../layout/theme';


// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/ionicons.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/css/third_party/ionicons.min.css';

import FroalaEditor from 'react-froala-wysiwyg';



// function Transition(props) {
//   return <Slide direction="up" {...props} />;
// }


class CampaignDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      campaign: {
        content: '',
        name: '',
        date: ''
      },
      options: {'empty':'empty'}
    }
  }
  componentDidMount() {
    const $this = this;
    const params = this.context.router.route.match.params;
    if(params.id!==undefined){
      this.setState({isUpdate:true},function(){
        Utils.getSingleData('campaign',params.id,function(res){
          if(res.status=='success'&&res.results!==undefined){
            $this.setState({campaign:res.results},function(){
            });
          }
        });
      });
    }
    Utils.getListData('subscribers','all',1,0,null,1,function(data){
      const arr = [];
      data.results.map((x,i)=>{
        arr.push(x.email);
      });
      $this.setState({emails:arr.join()});
    });

  }
  onEditorStateChange(value){
    const obj = this.state.campaign;
    obj.content = value;
    this.setState({campaign:obj});
  }
  handleFieldChange(e) {
    const $this = this;
    const obj = this.state.campaign;
    //console.log(e.target.id);
    obj[e.target.id] = e.target.value;
    $this.setState({campaign:obj},function(){
      //console.log($this.state);
    });
  }
  _save(){
    const $this = this;
    const data = this.state.campaign;

    data.date = (new Date()).toString();
    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('campaign',data, function(res) {
        //console.log(res);
        if (res.status == 'success') {
          $this.context.router.history.push('/campaign/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('campaign',data, function(res) {
        if (res.status == 'success') {
          $this.context.router.history.push('/campaign/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    $this._save();
  }
  handleSend(e){
    // obj.email = form.email;
    // obj.emailsubject = form.emailsubject;
    // obj.name = form.fullname;
    // obj.content = form.emailbody;
    // obj.sendto = form.sendto;
    var form = {};
    form.sendto = this.state.emails;
    form.email = 'info@bacs.vn';
    form.fullname = 'BACs Vietnam';
    form.emailsubject = this.state.campaign.name;
    form.emailbody = this.state.campaign.content;
    Utils.sendMail(form,function(data){
      console.log(data);
    });
  }
  handleCancel() {
    this.context.router.history.push('/campaign/');
  }
  render() {
    const classes = this.props.classes;
    const campaign = this.state.campaign;
    var editorConfig = Utils.setupEditor('campaign',this.state.options);
    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Send email</Typography>
        </Toolbar>
        <form className={classes.form} noValidate>
            <TextField id="name" label="Tiêu đề" className={classes.textField} value={this.state.campaign.name} onChange={(event) => this.handleFieldChange(event)} onBlur={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormLabel className={classes.label_shrink}>Nội dung</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={this.state.campaign.content}
              onModelChange={(value)=>this.onEditorStateChange(value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="secondary" onClick={(event) => this.handleSave(event)}>{language.VN.btnSave}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSend(event)}>Gửi</Button>
            </FormGroup>
          </form>
      </div>
    )
  }
}

CampaignDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
CampaignDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(CampaignDetail);
